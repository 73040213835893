const apiRoutes = {
  additionalEnrollFields: '/api/additional_enroll_fields',
  enrollmentProducts: '/api/enrollment_products',
  createCartItem: '/api/v1/cart_items',
  getCart: '/api/v1/carts',
  getCartByToken: (token) => `/api/v1/carts/${token}/cart_by_token`,
  updateCart: (cartId) => `/api/v1/carts/${cartId}`,
  updateCartItem: '/api/v1/cart_items',
  getStates: '/api/states',
  deleteCartItem: '/api/v1/cart_items',
  getEnroll: '/api/v1/enroll',
  enrollUser: '/api/v1/enroll/enroll_user',
  getEnrollProducts: '/api/v1/enroll_products',
  subscribeCartItem: (id) => `/api/v1/carts/${id}/subscribe`,
  checkUserIsEnroll: '/api/v1/enroll/check_user_is_enroll',
  spreedlyPaymentMethodURL: 'https://core.spreedly.com/v1/payment_methods.json',
  paymentRedirectURL: (apiUrlHost, cartId) =>
    `${apiUrlHost}/carts/${cartId}/payment_redirect_url`,
}

module.exports = apiRoutes
